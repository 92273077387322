import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Header from 'modules/Header';
import Footer from 'modules/Footer';
import SEO from 'modules/SEO';

const Layout = ({ children, noFooter }) => {
  const { datoCmsPage } = useStaticQuery(layoutQuery);
  if (!datoCmsPage) return null;
  const { blocks } = datoCmsPage;

  const { callToAction, menuLinks, campaignLogoText } = blocks.find(
    b => b.model.apiKey === 'header_navigation'
  );

  return (
    <>
      <SEO seo={datoCmsPage.seo} metaTags={datoCmsPage.seoMetaTags} />
      <Header
        campaignLogoText={campaignLogoText}
        menuLinks={menuLinks}
        callToAction={callToAction}
      />
      {children}
      {!noFooter && <Footer callToAction={callToAction} />}
    </>
  );
};

const layoutQuery = graphql`
  {
    datoCmsPage(title: { eq: "Talented in Denmark" }) {
      seo {
        title
        description
        image {
          url
        }
      }
      seoMetaTags {
        tags
      }
      blocks {
        ... on DatoCmsHeaderNavigation {
          model {
            apiKey
          }
          campaignLogoText
          callToAction {
            label
            href
          }
          menuLinks {
            label
            href
          }
        }
      }
    }
  }
`;

export default Layout;

import { useEffect, useRef, useState } from 'react';
import 'intersection-observer';
import { debounce } from 'utils';

export const useOnScreen = (options, reset) => {
  const ref = useRef();

  if (!options) {
    options = {
      rootMargin: '-10%',
      threshold: 0,
    };
  }

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) setVisible(true);
      if (reset && !entry.isIntersecting) {
        setVisible(false);
      }
    }, options);

    if (ref.current) observer.observe(ref.current);

    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, [ref, options, visible]);

  return [ref, visible];
};

export const useDeviceDetect = () => {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)
    );
    setMobile(mobile);
  }, []);

  return { isMobile };
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', debounce(handleResize, 100));

    handleResize();

    return () => window.removeEventListener('resize', debounce(handleResize, 100));
  }, []);

  return windowSize;
};

import React, { useState } from 'react';
import { Link } from 'gatsby';
// import { useLocation } from '@reach/router';
import tw, { css, styled } from 'twin.macro';
import Logo from 'components/Logo';
// import TiltButton from 'components/TiltButton';
import Menu from 'modules/Menu';
import { innerStyles } from 'components';
import { cn } from 'utils';

const headerStyles = css`
  padding-top: 2.15rem;
  ${tw`absolute z-999 w-full left-1/2 sm:pt-6 transform -translate-x-1/2 text-white transition-colors`}
`;

const headerInnerStyles = css`
  ${tw`px-5 md:px-10 lg:px-20 2xl:px-0 2xl:max-w-6xl 3xl:max-w-7xl sm:pt-10 md:pt-12`}
`;

const buttonStyles = css`
  width: 45px;
  height: 40px;
  ${tw`relative z-30 cursor-pointer ml-10`}
`;

const logoContainerStyles = css`
  width: 130px;
  ${tw`relative z-30 block sm:w-36 md:w-44 transition-colors duration-300`}
`;

const Towards = styled.h1`
  ${tw`towards text-center absolute pt-1 top-1/2 md:text-2xl`}
  font-size: 1.4rem;
  line-height: 0.86;
`;

const Buttons = tw.div`flex items-center relative z-30`;

const Header = ({
  // callToAction,
  menuLinks,
  campaignLogoText,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  // const { pathname } = useLocation();
  return (
    <header css={headerStyles} className={cn(isOpen && 'menu-is-open')}>
      <div css={[innerStyles, headerInnerStyles, tw`flex justify-between items-center`]}>
        <Link css={logoContainerStyles} to="/" onClick={() => setIsOpen(false)}>
          {campaignLogoText ? <Towards>{campaignLogoText}</Towards> : <Logo />}
        </Link>
        <Buttons>
          {/* {callToAction && !pathname.includes('book') && (
            <TiltButton label={callToAction.label} to={callToAction.href} tw=" hidden md:block" />
          )} */}
          <button
            css={buttonStyles}
            className={cn('button-toggle-menu', isOpen && 'is-open')}
            onClick={() => setIsOpen(value => !value)}
            type="button"
          >
            <div className="lines">
              <span />
              <span />
              <span />
            </div>
          </button>
        </Buttons>
        <Menu isOpen={isOpen} menuLinks={menuLinks} />
      </div>
    </header>
  );
};

export default Header;

import React from 'react';
import tw, { css } from 'twin.macro';
import { useStaticQuery, graphql } from 'gatsby';
import { Inner, sectionStyles, A } from 'components';
import Logo from 'components/Logo';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon,
} from 'components/Icons/SocialMedia';

const gradientStyles = css`
  background: linear-gradient(180deg, #0a1236 4.97%, rgba(138, 237, 213, 0.57) 100%);
`;

const soMeStyles = css`
  ${tw`relative block mr-4 z-10 rounded-full transition-colors duration-300`}
  &::after {
    content: '';
    ${tw`absolute w-5 h-5 top-1 left-1 z-minus-1 transition-colors duration-300`}
  }
  &:hover::after {
    ${tw`bg-white`}
  }
`;

const Footer = () => {
  const {
    datoCmsFooter: {
      companyName,
      address,
      email,
      facebookUrl,
      instagramUrl,
      linkedinUrl,
      twitterUrl,
      links,
    },
  } = useStaticQuery(query);

  return (
    <footer css={[sectionStyles, gradientStyles]}>
      <Inner tw="flex flex-col sm:flex-row">
        <div tw="w-1/2 mb-16 sm:(w-1/3 mb-0) lg:w-1/2">
          <a href="https://digitalhubdenmark.dk/" tw="inline-block">
            <Logo tw="w-48" />
          </a>
        </div>
        <div tw="flex flex-col sm:(flex-row) w-full sm:w-2/3 lg:w-1/2">
          <ul tw="w-1/2">
            {links.map(({ href, label }) => (
              <li key={href}>
                <A
                  href={href}
                  tw="inline-block mb-2 font-semibold text-xl mb-6 sm:(text-base mb-2)"
                >
                  {label}
                </A>
              </li>
            ))}
          </ul>
          <div tw="flex flex-col justify-between sm:w-1/2">
            <div tw="my-10 sm:my-0 text-lg sm:text-base">
              <span tw="font-semibold text-lg">{companyName}</span>
              <address tw="my-3 not-italic">{address}</address>
              <a href={`mailto: ${email}`} tw="font-semibold">
                {email}
              </a>
            </div>
            <div tw="mb-2 flex">
              {facebookUrl && (
                <A href={facebookUrl} css={soMeStyles} tw="hover:text-facebook">
                  <FacebookIcon />
                </A>
              )}
              {instagramUrl && (
                <A href={instagramUrl} css={soMeStyles} tw="hover:text-instagram">
                  <InstagramIcon />
                </A>
              )}
              {linkedinUrl && (
                <A href={linkedinUrl} css={soMeStyles} tw="hover:text-linkedin">
                  <LinkedInIcon />
                </A>
              )}
              {twitterUrl && (
                <A href={twitterUrl} css={soMeStyles} tw="hover:text-twitter">
                  <TwitterIcon />
                </A>
              )}
            </div>
          </div>
        </div>
      </Inner>
    </footer>
  );
};

const query = graphql`
  {
    datoCmsFooter {
      companyName
      address
      email
      facebookUrl
      instagramUrl
      linkedinUrl
      twitterUrl
      links {
        label
        href
      }
    }
  }
`;

export default Footer;

import arrow from 'assets/link-arrow.svg';
import externalLinkArrow from 'assets/external-link-arrow.svg';
import tw, { css, styled } from 'twin.macro';
import { ReactSVG } from 'react-svg';
import { useOnScreen } from 'utils/hooks';

export const innerStyles = tw`w-full mx-auto 2xl:max-w-6xl 3xl:max-w-7xl`;
export const Inner = ({ children, ...rest }) => (
  <div css={innerStyles} {...rest}>
    {children}
  </div>
);

export const sectionStyles = tw`relative w-full flex flex-col justify-between items-start py-12 px-5 md:(px-8 py-24) lg:px-20`;
export const SectionStyled = styled.section(({ visible }) => [
  sectionStyles,
  css`
    opacity: 0;
    transform: translateY(20px);
    transition: 1500ms opacity, 1500ms transform;
  `,
  visible &&
    css`
      opacity: 1;
      transform: translateY(0);
    `,
]);

export const Section = ({ children, show, ...rest }) => {
  const [ref, visible] = useOnScreen();
  return (
    <SectionStyled ref={ref} visible={(show && show) || visible} {...rest}>
      {children}
    </SectionStyled>
  );
};

export const Half = styled.div(() => [
  tw`md:w-full lg:w-1/2`,
  css`
    :first-of-type {
      ${tw`lg:pr-20`}
    }
    :last-of-type {
      ${tw`pl-0`}
    }
  `,
]);

export const PageTitle = tw.h1`font-bold mt-0 text-3xl md:text-5xl`;

export const SectionTitle = tw.h2`font-bold m-0 leading-tight text-xl sm:text-4xl`;
export const SectionHeader = tw.div`flex justify-between items-center w-full mb-10 flex-col items-start sm:(pl-0 flex-row items-center)`;

export const A = ({ children, href, withArrow, ...rest }) => (
  <a href={href} target="_blank" rel="noopener noreferrer" {...rest}>
    {children}
    {withArrow && <ReactSVG src={externalLinkArrow} />}
  </a>
);

export const contentStyles = css`
  font-size: 1.125rem;
    
  svg {
    transform: scale(1.5);
  }

  a {
    position: relative;
    margin-right: 22px;
  
    &:hover:before {
      background-color: white;
    }
  }

  a:before {
    position: absolute;
    content: '';
    height: 1px;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
  }

  a:after {
    position: absolute;
    margin-left: 3px;
    bottom: 2px;
    content: url('${externalLinkArrow}');
  }

  p {
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ul {
    margin: 1rem 0;
  }

  li {
    position: relative;
    padding-left: 2rem;
  }

  li:before {
    position: absolute;
    content: url('${arrow}');
    left: 0;
  }
`;

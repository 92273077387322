import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = () => {
  const { datoCmsPage, datoCmsSite } = useStaticQuery(
    graphql`
      query seoQuery {
        datoCmsSite {
          name
          faviconMetaTags {
            tags
          }
        }
        datoCmsPage(title: { eq: "Talented in Denmark" }) {
          title
          seo {
            title
            description
            image {
              url
            }
          }
        }
      }
    `
  );

  const metaDescription = datoCmsPage.seo?.description;
  const metaTitle = datoCmsPage.seo?.title;
  const metaKeywords = 'denmark, innovation, digital';
  const metaImage = datoCmsPage.seo?.image?.url;
  const metaAuthor = 'Digital Hub Denmark';
  const metaIcons = datoCmsSite.faviconMetaTags.tags.map(icon => icon.attributes);

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={metaTitle}
      titleTemplate={metaTitle}
      link={[...metaIcons]}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: metaKeywords,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: metaAuthor,
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'facebook-domain-verification',
          content: 'flduszzlcvcl5c4xtanxjqirohgc0j',
        },
      ]}
    >
      {/* <script type="text/plain" data-cookieconsent="marketing">
        {`!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '1044390472427666');
          fbq('track', 'PageView');`}
      </script> */}
    </Helmet>
  );
};

export default SEO;
